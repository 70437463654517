<!-- Employees -->
<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Employees-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Employees Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12">
            <!--begin: Wizard Form-->
            <el-form ref="form" :model="form" auto-complete="nope">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark">
                  <i class="flaticon-layers"></i> {{ title_text }}
                </h3>
                <!-- default-expand-all -->
                <div class="row">
                  <div class="col-xl-6 category_tree">
                    <div><h5>Product Category</h5></div>
                    <div style="margin-top: 20px" class="folder-content">
                      <el-tree
                        class="filter-tree"
                        :data="category"
                        :props="defaultProps"
                        node-key="id"
                        :expand-on-click-node="false"                        
                        @current-change="SelectCategory"
                        :highlight-current="true"
                        ref="tree"
                      >
                        <span class="custom-tree-node" slot-scope="{ data }">
                          <span class="icon-folder">
                            <i
                              class="el-icon-folder el-fw"
                              aria-hidden="true"
                            ></i>
                            <span class="icon-folder_text">
                              {{ data.label }}</span
                            >
                          </span>
                        </span>
                      </el-tree>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <el-row v-if="form.category_name">
                      <div class="col-xl-8">
                        <span
                          ><i class="el-icon-folder"></i>
                          {{ form.category_name }}</span
                        >
                        <span
                          style="float: right; cursor: pointer"
                          id="clear-button"
                          @click="clearCategory"
                        >
                          <i class="fas fa-times"></i>
                          <b-tooltip target="clear-button" variant="primary"
                            >Clear</b-tooltip
                          >
                        </span>
                        <hr />
                      </div>
                    </el-row>
                    <el-row style="margin-top: 20px">
                      <div class="my-2">
                        <v-btn small @click="CreateCategory" class="ml-5"
                          ><i class="el-icon-plus"></i> ADD</v-btn
                        >
                        <v-btn
                          small
                          class="ml-5"
                          v-if="form.category_name"
                          @click="EditCategory"
                          ><i class="el-icon-edit"></i> Edit</v-btn
                        >
                        <v-btn
                          small
                          class="ml-5"
                          v-if="form.category_name"
                          @click="deletecatecory"
                          ><i class="el-icon-delete"></i> Delete</v-btn
                        >
                      </div>
                    </el-row>
                  </div>
                </div>
              </div>

              <!--begin: Employees Actions -->

              <!--end: Employees Actions -->
            </el-form>
            <!--end: Employees Form-->
          </div>
        </div>
        <!--end: Employees Body-->
      </div>
    </div>
    <!--end: Employees-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/core/services/api.form.services";
import { getCategoryTree, deleteCategory, getAllcategory } from "@/api/product";
import CreateCategory from "@/components/CreateCategory/categoryTree";
import { globalEvents } from "@/utils/globalEvents";
import { getCategory } from "@/api/product";

export default {
  name: "add-category",
  data() {
    return {
      loading: false,
      expand: [],
      form: new Form({
        label: null,
        cat_status: 1,
        category_name: null,
        category_id: null,
      }),
      defaultProps: {
        children: "children",
        label: "label",
      },

      submit_btn_text: "Add Category",
      title_text: "Create Category",
      api_url: "/product/add-category",
      response_msg: "Category created successfully.",
      category: [],
      options: [
        { id: 1, text: "Active" },
        { id: 0, text: "In Active" },
      ],
      selectedOptionId: 1,
    };
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product Categories", route: "/product/categories" },
      { title: "Category" },
    ]);
    globalEvents.$on("CategoryCreated", (cat) => {
      if (cat) {
        this.category = [];
        this.category = cat[1];
        this.form.category_id = cat[0].id;
        this.form.category_name = cat[0].label;
        this.expand = [cat[0].parent_id];
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(cat[0].id);
        });
      }
    });
  },
  methods: {
    SelectCategory(currentObj) {
      this.form.category_id = currentObj.id;
      this.form.category_name = currentObj.label;
    },
    CreateCategory() {
      let Parentdata = this.$refs.tree.getCurrentNode();
      let data = {};
      if (Parentdata) {
        data = Parentdata;
      }
      this.$modal.show(
        CreateCategory,
        {
          parentData: data,
          category_action: "add",
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "600px",
        }
      );
    },

    EditCategory() {
      let currentSelectedNode = {};
      getCategory(this.form.category_id).then((response) => {
        currentSelectedNode = response.data.data[0];
        const currentNode = this.$refs.tree.getCurrentNode();
        let Parentdata = currentNode || currentSelectedNode;
        if (Parentdata) {
          this.$modal.show(
            CreateCategory,
            {
              parentData: Parentdata,
              category_action: "edit",
            },
            {
              draggable: true,
              transition: "pop-out",
              height: "auto",
              clickToClose: true,
              adaptive: true,
              width: "600px",
            }
          );
        }
      });
    },

    deletecatecory() {
      let Parentdata = this.$refs.tree.getCurrentNode();
      console.log(Parentdata);
      if (Parentdata.parent_id == "" || Parentdata.parent_id == null) {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to delete the Parent Category?",
            {
              title: "Please Confirm",
              size: "md",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "YES",
              cancelTitle: "NO",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              // this.loading = true;
              deleteCategory(Parentdata.id).then((response) => {
                this.category = [];
                if (response.data.status) {
                  // this.$showResponse('success', response.data.message);
                  this.category = response.data.data;
                  this.expand = [response.data.parent_id];
                  this.$nextTick(() => {
                    this.$refs.tree.setCurrentKey(response.data.parent_id);
                  });
                  this.form.category_id = "";
                  this.form.category_name = "";
                }
                //this.loading = false;
              });
            } else {
              return false;
            }
          });
      } else {
        this.$bvModal
          .msgBoxConfirm("Are you sure you want to delete ?", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              // this.loading = true;
              deleteCategory(Parentdata.id).then((response) => {
                this.category = [];
                if (response.data.status) {
                  // this.$showResponse('success', response.data.message);
                  this.category = response.data.data;
                  this.expand = [response.data.parent_id];
                  this.$nextTick(() => {
                    this.$refs.tree.setCurrentKey(response.data.parent_id);
                  });
                  this.form.category_id = "";
                  this.form.category_name = "";
                }
                //this.loading = false;
              });
            } else {
              return false;
            }
          });
      }
    },
    clearCategory() {
      this.$refs.tree.setCurrentKey();
      this.form.category_name = "";
      this.form.category_id = "";
    },

    dataURItoBlob(dataURI) {
      var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var binary = atob(dataURI.split(",")[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: mime });
    },

    getAllcategory() {
      this.loading = true;
      getCategoryTree().then((response) => {
        this.category = response.data.data;
        this.loading = false;
      });
    },
    viewAllCategory() {
      this.$router.push({ path: "/product/categories" });
    },
  },
  created() {
    this.getAllcategory();
    let cat_id = this.$route.params.cat_id;
    if (parseInt(cat_id) > 0) {
      this.submit_btn_text = "Update Category";
      this.title_text = "Update Category";
      this.action = "update";
      this.form.category_id = cat_id;
      getAllcategory().then((response) => {
        let category = response.data.data.data;
        category.map((cat) => {
          if (cat.id == this.form.category_id) {
            this.form.category_name = cat.label;
            this.form.category_id = Number(cat.id);
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(cat.id);
            });
          }
        });
      });
      this.expand = [parseInt(cat_id)];
    }
  },
};
</script>

